<template>
  <echarts-com
    v-loading="loading"
    id="businessCircle-parkingSpaceType"
    :options="options"
  />
</template>

<script>
import { formatRatio } from "@/utils/util";

export default {
  components: {
    echartsCom: () => import("@/components/echartsCom"),
  },
  data() {
    return {
      options: {},
      loading: false,
    };
  },
  created() {
    this.getOptions();
  },
  methods: {
    async getOptions(params = {}) {
      try {
        this.loading = true;
        let res = await this.$http.post(
          "/statistics/parking/type/count",
          params
        );
        if (res.code === 0) {
          let data = res.data.infoList.map((item) => {
            return {
              value: item.count,
              percentage: formatRatio(item.percentage) + "%",
              name: item.type || "",
            };
          });
          this.$set(this, "options", {
            tooltip: {
              trigger: "item",
            },
            legend: {
              type: "scroll",
              icon: "circle",
              orient: "vertical",
              right: 0,
              top: "center",
              itemWidth: 10,
              itemHeight: 10,
              formatter(labelName) {
                let item = data.find((item) => item.name === labelName);
                return `{name|${item.name}}{dividingLine||}{percentage|${item.percentage}}`;
              },
            },
            textStyle: {
              rich: {
                name: {
                  color: "white",
                },
                dividingLine: {
                  color: "#eee",
                  width: 10,
                  align: "right",
                },
                percentage: {
                  color: "white",
                  width: 55,
                  align: "right",
                },
              },
            },
            series: [
              {
                type: "pie",
                radius: ["67%", "90%"],
                left: 0,
                right: "50%",
                top: 0,
                bottom: 0,
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: "center",
                  color: "white",
                },
                emphasis: {
                  label: {
                    show: true,
                    fontWeight: "bold",
                  },
                },
                labelLine: {
                  show: false,
                },
                data,
              },
            ],
          });
        } else {
          this.$message.error(res.msg);
        }
        this.loading = false;
      } catch {
        this.$message.error("车位类型分析获取失败");
        this.loading = false;
      }
    },
  },
};
</script>
